import { Cascader, Form, TreeSelect } from 'antd';
import React, { useEffect, useState } from "react";

import { apiCall } from "../../utils/Api";

export default function BisacSelector(props) {

  const {
    value,
    size = "middle",
    setValue = () => { },
    strToArr,
    multiple = true
  } = props;

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);


  const values_array = strToArr(value);
  const [vals, setVals] = useState(
    {
      one: values_array[0] || "",
      two: values_array[1] || "",
      three: values_array[2] || "",
    }
  );


  useEffect(() => {
    const values_array = strToArr(value);

    setVals(
      {
        one: values_array[0] || "",
        two: values_array[1] || "",
        three: values_array[2] || "",
      }
    )

  }, [value])


  useEffect(() => {
    let str = Object.values(vals).join(",");
    if (str !== value) {
      setValue(str);
    }

  }, [vals])

  const markNonBooks = (_arr) => {

    return _arr.map(item => {
      if (!item.hasOwnProperty("id")) {
        return { ...item, "label": item.label + " (can't select)", "selectable": false, id: item.label, "children": markNonBooks(item.children) }
      }
      if (item.hasOwnProperty("id") && item.id.substr(0, 1) === "_") {
        return { ...item, "label": item.label + " (nonbook)", "selectable": true, "children": markNonBooks(item.children) }
      } else {
        return { ...item, "selectable": true }
      }
    })
  }

  const getBisacs = () => {
    apiCall("title/getBisacs", {}, (_status, _result) => {
      if (_status) {
        setResults(markNonBooks(_result));
        setLoading(false);
      }
    })
  }

  useEffect(getBisacs, []);

  const getOptions = (_results) => {
    return _results.map((item, index) => {
      return {
        value: item.id,
        key: item.id,
        title: (<div>{item.label} <small> - <em>({item.id})</em></small></div>),
        selectable: item.selectable,
        children: getOptions(item.children)
      }
    })
  }

  const drawFields = () => {


    return (
      <>
        <label><small>Bisac 1</small></label>
        <TreeSelect size={size} style={{ "width": "100%", "fontSize": (size === "small") ? "12px" : "14px" }} loading={loading} onChange={(e) => setVals({ ...vals, "one": e })} value={vals?.one} showSearch placeholder={(loading) ? "Loading..." : "Select Bisac"} treeData={getOptions(results)} />
        <label><small>Bisac 2</small></label>
        <TreeSelect size={size} style={{ "width": "100%", "fontSize": (size === "small") ? "12px" : "14px" }} loading={loading} onChange={(e) => setVals({ ...vals, "two": e })} value={vals?.two} showSearch placeholder={(loading) ? "Loading..." : "Select Bisac"} treeData={getOptions(results)} />
        <label><small>Bisac 3</small></label>
        <TreeSelect size={size} style={{ "width": "100%", "fontSize": (size === "small") ? "12px" : "14px" }} loading={loading} onChange={(e) => setVals({ ...vals, "three": e })} value={vals?.three} showSearch placeholder={(loading) ? "Loading..." : "Select Bisac"} treeData={getOptions(results)} />
      </>
    )
  }


  return drawFields();

  // return (
  //   <>

  //     <pre>{JSON.stringify(vals, null, 2)}</pre>
  //     <TreeSelect size={size} style={{ "width": "100%", "fontSize": (size === "small") ? "12px" : "14px" }} multiple={multiple} loading={loading} onChange={(e) => setValue(e)} value={(value) ? strToArr(value) : undefined} showSearch placeholder={(loading) ? "Loading..." : "Select Bisac"} treeData={getOptions(results)} />
  //   </>
  // );
}