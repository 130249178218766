import { FormOutlined } from '@ant-design/icons';
import { Badge, Button, message, Space, Table, Tooltip } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { useState } from "react";

import conditional from "../../tools/conditional";
import { apiCall } from "../../utils/Api";
import { ucfirst } from "../../utils/Utils";
import TitleRenderers from "./TitleRenderers";
import Html from '../../utils/Html';

export default function TitleEditField(props) {
    const { editing, field, isbn, annotation = false, edit = {}, tooltip = "", displayOnly = false } = props;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({ rows: [], type: "", can_use_pick: false });
    const [value, setValue] = useState("");
    const [visible, setVisible] = useState(false);
    const [row, setRow] = useState(null);
    const [valid, setValid] = useState(true);
    const [type, setType] = useState("title_heap");
    const [title, setTitle] = useState("Edit");


    const name = (props.name) ? props.name : ucfirst(field.replace("_", " "));

    const close = () => {
        setVisible(false);
        setValid(true);
        setRow(null);
    }

    const addRowKeys = (_arr) => {
        return (_arr.map((item) => {
            item["key"] = item.row_id;
            return item;
        }))
    }

    const getEdit = (_visible, _title = "Edit") => {
        if (visible) {
            close();
            return;
        }
        setTitle(_title);
        setValue("");
        setLoading(true);
        let _obj = {}
        _obj.isbn = isbn;
        _obj.field = field;
        // eslint-disable-next-line default-case
        switch (_obj.field) {
            case "main_description":
                _obj.field = "annotation_1"
                break;
            case "short_description":
                _obj.field = "annotation_2"
                break;
            case "long_description":
                _obj.field = "annotation_3"
                break;
            case "review_quote":
                _obj.field = "annotation_8"
                break;
            case "reader_description":
                _obj.field = "annotation_12"
                break;
            case "biographical_note":
                _obj.field = "annotation_13"
                break;
        }
        // overwrite field with id if it's an annotation
        if (annotation) {
            _obj.field = "annotation_" + annotation.id.toString();
        }
        if (_visible) {
            apiCall("title/getSpecificEdit", _obj, (_status, _result) => {
                if (_status) {
                    addRowKeys(_result.rows)
                    setType(_result.type);
                    setData(_result);
                    setVisible(true);
                    setLoading(false);
                } else {
                    message.error("Not available.")
                }
            })
        }
    }

    const saveEdit = () => {
        if (!value) {
            setValid(false);
            return;
        }
        let obj = {}
        obj.value = value;
        obj.isbn = isbn;
        if (annotation) {
            obj.note_id = annotation.id;
            obj.field = field;
            if (row) {
                obj.row_id = row;
            }
            apiCall("title/saveAnnotation", obj, (_status, _result) => {
                if (_status) {
                    message.success("Update requested");
                } else {
                    message.error(_result.error);
                }
                close()
            })
        } else {
            obj.type = type;
            obj.field = field;
            if (row) {
                obj.row = row;
            }
            apiCall("title/save", obj, (_status, _result) => {
                if (_status) {
                    message.success("Update requested");
                } else {
                    message.error(_result.error)
                }
                close()
            })
        }
    }

    const deleteEdit = () => {
        let obj = {}
        obj.type = type;
        obj.isbn = isbn;
        if (annotation) {
            if (row) {
                obj.row_id = row;
            }
            obj.note_id = annotation.id;
            apiCall("title/deleteAnnotation", obj, (_status, _result) => {
                if (_status) {
                    message.success("Record deleted");
                } else {
                    message.error(_result.error)
                }
                close()
            })
        } else {
            obj.field = field;
            if (row) {
                obj.row = row;
            }
            apiCall("title/delete", obj, (_status, _result) => {
                if (_status) {
                    message.success("Record deleted");
                } else {
                    message.error(_result.error)
                }
                close()
            })
        }
    }

    const columns = [
        {
            title: <small></small>, width: "20px", style: { "padding": "0px" }, dataIndex: 'fs', key: 'fs', render: (e, f) => {
                return (<Badge style={{ "paddingLeft": "12px" }} color={(f.is_editable) ? "#87ceeb" : "#d3d3d3"} />)
            }
        },
        {
            title: <small>Value</small>, dataIndex: 'data', key: 'data', render: (e, f) => {


                if (field === "bisac_subjects") {
                    return (
                        <div style={{ "width": "240px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>
                            <Tooltip title={<small>{(e) ? e.split(",").map(item => "[" + item + "]") : ""}</small>}>{(e) ? e.split(",").map(item => "[" + item + "]") : ""}</Tooltip>
                        </div>
                    )
                }

                if (field === "title") {
                    return (
                        <div style={{ "width": "220px" }}>
                            {e}
                        </div>
                    )
                }

                if (field === "main_description" || field === "short_description" || field === "long_description" || field === "reader_description") {
                    return (
                        <Tooltip overlayInnerStyle={{"width" : "700px"}} title={<small>{e}</small>} ><div style={{ "width": "220px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>
                            {e}
                        </div></Tooltip>
                    )
                }


                return (e) ? <div style={{ "width": "100px", "overflow": "hidden", "textOverflow": "ellipsis", "whiteSpace": "nowrap" }}>
                    {e}
                </div> : "";
            }
        },
        { title: <small>Source</small>, dataIndex: 'source_display', key: 'source_display' },
        {
            title: <small>Created</small>, dataIndex: 'date_created', key: 'date_created', render: (e) => {
                return (e) ? <span>{moment(e * 1000).format("MMM Do, YYYY")}</span> : "";
            }
        },
        {
            title: <small>Modified</small>, dataIndex: 'date_changed', key: 'date_changed', render: (e) => {
                return (e) ? <span>{moment(e * 1000).format("MMM Do, YYYY")}</span> : "";
            }
        },
        { title: <small></small>, width: "0x", style: { "padding": "0px" }, dataIndex: 'bs', key: 'bs' },
        { title: <small>Priority</small>, dataIndex: 'title_weight', key: 'title_weight' },
        { title: <small></small>, width: "0x", style: { "padding": "0px" }, dataIndex: 'bs', key: 'bs' },
    ]

    // "title_weight": 1001,
    // "data": "I Love Corgis! : this is the subtitle by bookmanager",
    // "source_display": "",
    // "date_created": 1628183900,
    // "date_changed": 1628183900,
    // "row_id": 76076143,
    // "isbn": "ATEST00000000",
    // "eisbn": "Ryan_Y-Z0UVpa4PqhAdL4w",
    // "banned_bisacs": null,
    // "is_editable": false

    const onRowClick = (_record) => {
        if (_record.is_editable) {
            if (row === _record.row_id) {
                setRow(null);
            } else {
                setRow(_record.row_id);
            }
        } else {
            setRow(null);
        }
        setValue(_record.data);
    }

    const drawEditForm = () => {

        const _title = (field !== "title") ? title : <>Edit title: <em><small>{edit[field]}</small></em></>;
        return (
            <Modal title={_title} width={800} style={{"marginTop" : "50px"}} onCancel={() => setVisible(false)} footer={null} visible={visible}>
                <div style={{ "margin": "-12px -16px" }}>
                    <conditional.true value={data.rows.length > 0}>
                        <Table
                            size="small"
                            rowClassName={(record) => { return (record.row_id === row) ? "ant-table-row-selected" : "" }}
                            loading={loading}
                            columns={columns}
                            dataSource={data.rows}
                            pagination={false}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => { onRowClick(record) }, // click row
                                };
                            }}
                        />
                    </conditional.true>
                    <div className="shim"></div>
                    <div style={{ "padding": "10px 20px" }}>
                        <TitleRenderers isbn={isbn} can_use_pick={data.can_use_pick} valid={valid} edit={edit} value={value} setValue={setValue} field={field} />
                        <div style={{ "float": "right" }}>
                            <Space>
                                <Button size="small" onClick={() => close()} ><small>Cancel</small></Button>
                                {/* <conditional.true value={(row)}>
                                    <Button danger size="small" onClick={() => deleteEdit()} ><small>Delete</small></Button>
                                </conditional.true> */}
                                <Button size="small" onClick={() => saveEdit()} type="primary"><small>{(row) ? "Update" : "Save Changes"}</small></Button>
                            </Space>
                        </div>
                        <br clear="all" />
                        <div className="shim"></div>
                    </div>
                </div>
            </Modal>
        )
    }

    if (!editing) {
        return props.children;
    }

    if (displayOnly) {
        return (<><span><em style={{ "opacity": "0.6" }}>{field} displays as: </em></span>{props.children}</>)
    }

    return (
        <>
            {props.children}
            {drawEditForm()}
            <conditional.true value={(!props.children)}>
                <conditional.true value={(edit[field])}>
                    {edit[field]}
                </conditional.true>
            </conditional.true>
            <Tooltip title={tooltip}><a onClick={() => getEdit(true, "Edit " + name)} > {(annotation) ? <small><nobr>{"Edit " + name + " "}</nobr></small> : <small style={{ "fontSize": "12px" }}>{((edit[field])) ? <><nobr>Edit {field} <FormOutlined style={{ "fontSize": "11px", "lineHeight": "0px" }} /></nobr></> : <>[{field}]</>}</small>}<small></small></a></Tooltip>
        </>);
}